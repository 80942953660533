/**
 *                             _ooOoo_
 *                            o8888888o
 *                            88" . "88
 *                            (| -_- |)
 *                            O\  =  /O
 *                         ____/`---'\____
 *                       .'  \\|     |//  `.
 *                      /  \\|||  :  |||//  \
 *                     /  _||||| -:- |||||-  \
 *                     |   | \\\  -  /// |   |
 *                     | \_|  ''\---/''  |   |
 *                     \  .-\__  `-`  ___/-. /
 *                   ___`. .'  /--.--\  `. . __
 *                ."" '<  `.___\_<|>_/___.'  >'"".
 *               | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *               \  \ `-.   \_ __\ /__ _/   .-` /  /
 *          ======`-.____`-.___\_____/___.-`____.-'======
 *                             `=---='
 *          ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
 *                     佛祖保佑        永无BUG
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 根据环境来判断是否提示消息
Vue.config.productionTip = false

import './assets/css/index.scss'

// 全局引入element-ui
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

import globalVariable from "./assets/js/globalVariable";
Vue.prototype.commonsVariable = globalVariable;

// 格式化useragent
import detect from '@/assets/js/detect'
Vue.prototype.detect = detect


require('echarts-wordcloud');

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
