import request from 'utils/request'

export function login(data) {
  return request({
    url: '/studentapi/login_new',
    params: data,
  })
}
/**作业列表 */
export function paperList(params) {
  return request({
    url: '/studentapi/report/list_new',
    params
  })
}
export function paperDetail(data) {
  return request({
    url: '/studentapi/report/detail_new',
    params: data,
  })
}
// 开始测评
export function start_new(data) {
  return request({
    url: '/studentapi/report/start_new',
    params: data,
  })
}
// 提交单题答案
export function submitQuestion(data) {
  return request({
    url: '/studentapi/question/submit_new',
    params: data,
  })
}
// 提交整个测评
export function submitPaper(data) {
  return request({
    url: '/studentapi/report/submit_new',
    params: data,
  })
}
// 获取作业/练习答案
export function paperResult(data) {
  return request({
    url: '/studentapi/report/result_new',
    params: data,
  })
}
// 生成错题巩固接口
export function wrongPaperDetail(data) {
  return request({
    url: '/studentapi/report/solid_mistake',
    params: data,
  })
}
// 强化提高
export function studyPaperDetail(data) {
  return request({
    url: '/studentapi/report/streng_promote',
    params: data,
  })
}
// 试卷分析
export function analyInfo(data) {
  return request({
    url: '/studentapi/report/analysis/info',
    params: data,
  })
}
// 查看详情
export function analyDetail(data) {
  return request({
    url: '/studentapi/report/analysis/detail',
    params: data,
  })
}
// 查看子集知识点
export function getKnowledgeLevel(data) {
  return request({
    url: '/studentapi/report/get_knowledge_level',
    params: data,
  })
}
// 注册
export function register(data) {
  return request({
    url: '/studentapi/register',
    data,
    method: 'post'
  })
}
// 发送验证码
export function sendCode(data) {
  return request({
    url: '/studentapi/sms/send',
    params: data,
  })
}
// 核对验证码
export function forget(data) {
  return request({
    url: '/studentapi/forget',
    data,
    method: 'post'
  })
}
// 修改密码
export function changePassword(data) {
  return request({
    url: '/studentapi/change',
    data,
    method: 'post'
  })
}
// 小学注册-公共参数返回
export function registercommon(data) {
  return request({
    url: '/studentapi/common',
    params: data
  })
}