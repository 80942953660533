<template>
  <div class="layout">
    <div class="header fr">
      <div class="header-left fr" :class="{isPad: isPad}">
        <router-link tag="span" to="/mypaper" class="header-left-title">华师东方数学能力测评系统</router-link>
        <router-link tag="span" class="header-left-section" :class="{active: isActive == 1}" active-class="active" to="/mypaper">我的试卷</router-link>
        <router-link tag="span" class="header-left-section" :class="{active: isActive == 2}" active-class="active" to="/myexerciestion">我的练习</router-link>
      </div>
      <div class="header-right fr">
        <img src="../assets/images/avatar.jpg" alt="">
        <el-dropdown trigger="click" @command="clickDropdown">
          <span>{{isPad ? '' : userInfo.nickname}}<i class="el-icon-arrow-down el-icon--right"></i></span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="accountInfo">账号信息</el-dropdown-item>
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="container">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import { removeUserinfo, getUserinfo } from 'utils/util'

export default {
  data() {
    return {
      isActive: 1,
      isPad: false,
      userInfo: {}
    }
  },
  provide() {
    return {
      app: this,
    }
  },
  watch: {
    $route() {
      this.setActive()
    }
  },
  created () {
    this.setActive()
    this.setIsPad()
  },
  mounted () {
    let obj = getUserinfo()
    if (obj) {
      obj = JSON.parse(obj)
      this.userInfo = obj
    }
  },
  methods: {
    setIsPad() {
      let isPad = navigator.maxTouchPoints > 1
      var sua = this.detect.parse(navigator.userAgent);
      let isAndroidPad = sua.os.family === 'Android' && sua.device.type === 'Desktop'
      this.isPad = isPad || isAndroidPad
    },
    setActive() {
      let path = this.$route.path
      if (path === '/userinfo') {
        this.isActive = 3
      } else if (path === '/myexerciestion') {
        this.isActive = 2
      } else {
        this.isActive = 1
      }
    },
    clickDropdown(key) {
      if (key === "accountInfo") { // 账号信息
        this.$router.push({
          path: '/userinfo'
        })
      } else if (key === "logout") {
        removeUserinfo()
        this.$router.push('/login')
      }
    }
  },
}
</script>
<style scoped lang="scss">
.layout {
  background: #F5F9FA;
  min-height: 100vh;
  padding-bottom: 20px;
  overflow-x: hidden;
  .header {
    min-width: 768px;
    height: 70px;
    background: white;
    align-items: center;
    justify-content: space-between;
    padding: 0 22px 0 20px;
    box-sizing: border-box;
    .header-left {
      align-items: center;
      &.isPad {
        flex-grow: 1;
        margin-right: 140px;
        justify-content: space-between;
        .header-left-title {
          font-size: 24px;
          margin-right: 0;
        }
        .header-left-section {
          font-size: 16px;
          margin-left: 0;
        }
      }
      .header-left-title {
        font-size: 27px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #329BE4;
        line-height: 27px;
        margin-right: 30px;
        display: inline-block;
        height: 70px;
        line-height: 70px;
        cursor: pointer;
      }      
      .header-left-section {
        font-size: 18px;
        color: #555;
        margin-left: 64px;
        font-weight: 300;
        height: 70px;
        line-height: 70px;
        padding: 0 6px;
        border-bottom: 2px solid white;
        box-sizing: border-box;
        cursor: pointer;
        &.active {
          color: #333;
          font-weight: bold;
          border-bottom: 2px solid #5DAEE6;
        }
        &:hover {
          font-weight: bold;
          color: #333;
        }
      }
    }
    .header-right {
      align-items: center;
      img {
        width: 48px;
        border-radius: 50%;
        margin-right: 8px;
      }
      span {
        font-size: 18px;
        color: #333;
        cursor: pointer;
        height: 25px;
        line-height: 25px;
        padding: 0 8px;
        display: inline-block;
      }
    }
  }
  .container {
    max-width: 1350px;
    min-width: 768px;
    margin: 0 auto;
  }
}
</style>
