import Cookies from "js-cookie";
import {USER_INFO} from "./config";
import { XXTEA } from "../assets/js/xxtea.min";
import {ENCRY_KEY} from 'utils/config'


export function getUserinfo() {
  return Cookies.get(USER_INFO)
}
export function removeUserinfo() {
  return Cookies.remove(USER_INFO)
}
export function setUserinfo(value) {
  return Cookies.set(USER_INFO, value)
}
export function getToken() {
  let obj = getUserinfo()
  let token = null
  if (obj) {
    try {
      obj = JSON.parse(obj)
      token = obj.token
    } catch (e) {
      console.log(e);
    }
  }
  return token
}
/**简易版的深拷贝【宇辰兄说此方法比较耗性能，但是我就是不改，就是玩儿】 */
export function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj))
}
export function encry(val) {
  if (typeof val === "object") {
    Object.keys(val).forEach(
      (v) => (val[v] = XXTEA.encryptToBase64(val[v], ENCRY_KEY))
    );
    return val;
  } else {
    return XXTEA.encryptToBase64(val, ENCRY_KEY);
  }
}