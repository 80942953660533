<template>
  <div class="login fr" :class="{pad: isPad}"  v-if="ispc">
    <div class="login-left">
      <div class="login-header fr">
        <img :src="logo" alt="" />
        <span>华师东方数学能力测评系统</span>
      </div>
      <img class="login-bg" :src="loginBg" alt="" />
    </div>
    <LoginContainer v-if="isPad" />
    <div class="login-right" v-if="!isPad">
      <LoginContainer />
    </div>
  </div>
  <div v-else class="tips">请在pc端打开测评页面</div>
</template>

<script>
import logo from 'imgs/logo.svg'
import loginBg from 'imgs/login_bg.png'

import LoginContainer from 'comps/loginContainer'

export default {
  data() {
    return {
      logo,
      loginBg,
      isPad: false,
      ispc:false
    };
  },
  created () {
    let isPad = navigator.maxTouchPoints > 1
    var sua = this.detect.parse(navigator.userAgent);
    let isAndroidPad = sua.os.family === 'Android' && sua.device.type === 'Desktop'
    this.isPad = isPad || isAndroidPad
    this.browserRedirect()
    window.onresize=()=>{
      this.browserRedirect()
    }   
  },
  components: {
    LoginContainer,
  },
  methods:{
    browserRedirect(){
      var sUserAgent = navigator.userAgent.toLowerCase();
      var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
      var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
      var bIsMidp = sUserAgent.match(/midp/i) == "midp";
      var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
      var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
      var bIsAndroid = sUserAgent.match(/android/i) == "android";
      var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
      var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
      if(bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM) {
        this.ispc=false
      }else{
        this.ispc=true
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.login {
  min-width: 1350px;
  position: relative;
  &.pad {
    min-width: 100vw;
  }
  .login-left {
    flex-grow: 1;
    height: 100vh;
    background-image: linear-gradient(#3b75fe, #0f43bd);
    position: relative;
    .login-header {
      padding-left: 50px;
      padding-top: 48px;
      align-items: center;
      color: white;
      img {
        width: 68px;
        height: auto;
        margin-right: 17px;
      }
      span {
        font-size: 30px;
        font-weight: bold;
      }
    }
    .login-bg {
      width: 608px;
      height: auto;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .login-right {
    width: 43%;
    height: 100vh;
    background: #f6f6f6;
    position: relative;
  }
}
.tips{
    display: flex;
    justify-content: center;
    padding-top: 50px;
    font-size: 20px;
}
</style>