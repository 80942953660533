// 用户信息
export const USER_INFO = 'USER_INFO'

// 定义全局的配置
export const ERROR_CODE = 0;
// 答案选项
export const ANSWER_OPTIONS = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']
export const FIGURE = ["一", "二", "三", "四", "五", "六", "七", "八", "九", "十"];

// 试卷状态
export const PAPER_STATUS_LIST = [
  {
    id: null,
    title: '全部'
  },
  {
    id: 1,
    title: '已完成'
  },
  {
    id: 0,
    title: '未完成'
  },
  {
    id: 2,
    title: '已过期'
  }
]
// 所属年级
export const GRADE_LIST = [
  {
    id: null,
    title: '全部'
  },
  {
    id: 1,
    title: '一年级'
  },
  {
    id: 2,
    title: '二年级'
  },
  {
    id: 3,
    title: '三年级'
  },
  {
    id: 4,
    title: '四年级'
  },
  {
    id: 5,
    title: '五年级'
  },
  {
    id: 6,
    title: '六年级'
  },
  {
    id: 7,
    title: '初一'
  },
  {
    id: 8,
    title: '初二'
  },
  {
    id: 9,
    title: '初三'
  },
  {
    id: 10,
    title: '高一'
  },
  {
    id: 11,
    title: '高二'
  },
  {
    id: 12,
    title: '高三'
  },
]
export const ENCRY_KEY = "Cm1cU6s5a5iZ8dFR";