import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/Layout.vue'
import Login from 'views/Login.vue'
import notFound from 'views/404'

import { getToken } from 'utils/util'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/mypaper',
    children: [
      {
        path: 'mypaper',
        component: () => import(/* webpackChunkName: "mypaper" */ '@/views/MyPaper'),
      },
      {
        path: 'starttest',
        component: () => import(/* webpackChunkName: "starttest" */ '@/views/StartTest'),
      },
      {
        path: 'paperfenxi',
        component: () => import(/* webpackChunkName: "paperfenxi" */ '@/views/PaperFenxi'),
      },
      {
        path: 'intensivestudy',
        component: () => import(/* webpackChunkName: "intensivestudy" */ '@/views/IntensiveStudy'),
      },
      {
        path: 'wrongtopic',
        component: () => import(/* webpackChunkName: "wrongtopic" */ '@/views/WrongTopic'),
      },
      {
        path: 'myexerciestion',
        component: () => import(/* webpackChunkName: "myexerciestion" */ '@/views/MyExerciestion'),
      },
      {
        path: 'studyreport',
        component: () => import(/* webpackChunkName: "studyreport" */ '@/views/StudyReport'),
      },
      {
        path: 'userinfo',
        component: () => import(/* webpackChunkName: "userinfo" */ '@/views/UserInfo'),
      },
    ]
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/register',
    component: Login
  },
  {
    path: '/forget',
    component: Login
  },
  {
    path: '*',
    component: notFound,
  }
]

const router = new VueRouter({
  routes
})

// 解决路由在 push/replace 了相同地址报错的问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

const whiteList = ['/login', '/register', '/forget'];
router.beforeEach(async (to, from, next) => {
  if (getToken()) { // 根据token来判断是否跳转到登录页面
    if (to.path === '/login') {
      next({ path: '/' });
    } else {
      next()
    }
  } else {
    if (whiteList.includes(to.path)) { // 在免登录白名单，直接进入
      next();
    } else {
      next('/login'); // 否则全部重定向到登录页
      // NProgress.done()
    }
  }
})

router.afterEach(() => {
  // console.log('路由完成');
})

export default router
