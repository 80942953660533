<template>
  <div>
    <h2>新用户注册</h2>
    <el-form
      :model="registerForm"
      :rules="rule"
      ref="registerForm"
      class="login-form"
    >
      <el-form-item prop="name">
        <el-input
          placeholder="姓名"
          v-model="registerForm.name"
          class="user"
          autocomplete="off"
          @input="() => (registerForm.name = registerForm.name.trim())"
        >
        </el-input>
      </el-form-item>
      <el-form-item prop="grade_id" class="user">
        <el-select v-model="registerForm.grade_id"  placeholder="评估年级" @change="changegrade" disabled>
          <el-option
            v-for="item in gradeList"
            :key="item.grade_id"
            :label="item.grade_name"
            :value="item.grade_id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="phone">
        <el-input
          placeholder="手机号码"
          v-model="registerForm.phone"
          class="user"
          autocomplete="off"
          @input="() => (registerForm.phone = registerForm.phone.trim())"
        >
        </el-input>
      </el-form-item>
      <el-form-item prop="code" class="code-form">
        <el-input
          placeholder="短信验证码"
          v-model="registerForm.code"
          class="user"
          autocomplete="off"
          @input="() => (registerForm.code = registerForm.code.trim())"
        >
        </el-input>
        <div class="code">
          <span @click="sendCode">{{startInterval ? '重新发送' : '发送验证码'}}</span>
          <span v-if="startInterval">（{{interval}}s）</span>
        </div>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          show-password
          placeholder="新密码"
          v-model="registerForm.password"
          class="user"
          autocomplete="off"
          @input="() => (registerForm.password = registerForm.password.trim())"
        >
        </el-input>
      </el-form-item>
      <el-form-item prop="password_confirmation">
        <el-input
          show-password
          placeholder="再次验证新密码"
          v-model="registerForm.password_confirmation"
          class="user"
          autocomplete="off"
          @input="() => (registerForm.password_confirmation = registerForm.password_confirmation.trim())"
        >
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          class="register-btn"
          type="primary"
          @click="register"
          :loading="isLoading"
          >注册</el-button
        >
      </el-form-item>
      <div class="login-account">
        <span @click="login">已有账号，立即登录</span>
      </div>
    </el-form>
  </div>
</template>

<script>
// 引入首页背景图片
import { register, sendCode, registercommon } from "api";

import { ERROR_CODE } from "utils/config";
import { setUserinfo, encry, deepClone } from "utils/util";

export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('密码不能为空'));
      } else if (value.length < 6 || value.length > 20) {
        callback(new Error('密码格式有误！'))
      } else if (value !== this.registerForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      gradeList: [
        {
          grade_name: '高一',
          grade_id: 10
        }
      ],
      isLoading: false,
      registerForm: {
        grade_id: 10,
        class_id:"",
        origin_book_id:"",
        name: '',
        phone: '',
        code: '',
        password: '',
        password_confirmation: '',
      },
      rule: {
        name: [
          { 
            required: true, 
            message: '姓名不能为空', 
            trigger: 'blur' 
          },
          { 
            min: 2, 
            max: 12, 
            message: '长度在2-12个字符', 
            trigger: 'blur' 
          }
        ],
        grade_id:[
          { 
            required: true, 
            message: '年级不能为空', 
            trigger: 'change' 
          },    
        ],
        phone: [
          { 
            required: true, 
            message: '手机号不能为空', 
            trigger: 'blur' 
          },
          { 
            min: 6, 
            max: 16, 
            message: '长度在6-16个字符', 
            trigger: 'blur' 
          }
        ],
        code: [
          { 
            required: true, 
            message: '验证码不能为空', 
            trigger: 'blur' 
          },
          { 
            min: 4, 
            max: 6, 
            message: '长度在4-6个字符', 
            trigger: 'blur' 
          }
        ],
        password: [
          { 
            required: true, 
            message: '密码不能为空', 
            trigger: 'blur' 
          },
          { 
            min: 6, 
            max: 20, 
            message: '密码格式有误！', 
            trigger: 'blur' 
          }
        ],
        password_confirmation: [
          { 
            validator: validatePass 
          }
        ],
      },
      startInterval: false,
      interval: 60
    }
  },
  methods: {
    login() {
      this.$router.replace({path: '/login', query: {action: 'login'}})
    },
    async sendCode() {
      let phone = this.registerForm.phone
      if (phone.length === 0) {
        this.$message.warning('请输入手机号码')
        return
      }
      if (phone.length < 6 || phone.length > 20) {
        this.$message.warning('手机号码格式有误！')
        return
      }
      if (this.startInterval) {
        return
      }
      let res = await sendCode({phone: encry(phone)})
      if (res.error === ERROR_CODE) {
        this.$message.success(res.msg)
      }
      this.startInterval = true
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        if (this.interval === 0) {
          clearInterval(this.timer)
          this.startInterval = false
          this.interval = 60
        }
        this.interval--
      }, 1000)
    },
    /**登录 */
    register() {
      this.$refs.registerForm.validate(async (valid) => {
        if (valid) {
          let _registerForm = deepClone(this.registerForm)
          _registerForm.password = encry(_registerForm.password)
          _registerForm.password_confirmation = encry(_registerForm.password_confirmation)

          this.isLoading = true;
          let res = await register(_registerForm);
          this.isLoading = false;
          if (res.error == ERROR_CODE) {
            let obj = res.data || {};
            setUserinfo(JSON.stringify(obj));
            this.$router.push("/");
          }
        }
      });
    },
    async registercommon(){
      let res = await registercommon();
      if (res.error === ERROR_CODE) {
        let grade_list = res.data.grade_list;
        this.gradeList=grade_list
      }
    },
    changegrade(val){
      this.gradeList.forEach(ele=>{
        if(ele.grade_id==val){
          this.registerForm.class_id=ele.class_id;
          this.registerForm.origin_book_id=ele.origin_book_id
        }
      })
    }
  },
  created () {
    // let isPrimarySchool = location.origin.indexOf('kidstu.52shuxue.com') >= 0
    // if (isPrimarySchool) {
    //   this.registerForm.grade_id = 1
    // }
    // 小学注册-公共参数返回
    // this.registercommon()
  },
}
</script>

<style lang="scss" scoped>
h2 {
  margin-top: 50px;
  font-size: 40px;
  font-weight: bold;
  color: black;
}
.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  .user {
    width: 460px;
    font-size: 18px;
    ::v-deep .el-select {
      width: 100%;
    }
  }
  .register-btn {
    border-radius: 32px;
    width: 190px;
    height: 65px;
    background: #1f56d5;
    border: 1px solid #1f56d5;
    font-size: 20px;
    margin-top: 40px;
  }
  .pass-form {
    margin-bottom: 0;
  }
  .code-form {
    position: relative;
    .code {
      position: absolute;
      right: 15px;
      top: 0;
      span {
        color: #878A93;
        font-size: 18px;
        &:first-child {
          color: #1F56D5;
          cursor: pointer;
        }
      }
    }
  }
  .login-account {
    text-align: center;
    width: 100%;
    margin-bottom: 80px;
    span {
      color: #1F56D5;
      font-size: 16px;
      cursor: pointer;
    }
  }
}
</style>