import axios from 'axios'
import { Message } from 'element-ui'
import { ERROR_CODE } from './config';
import { getToken, getUserinfo, removeUserinfo } from './util';
import store from '@/store'

import router from '../router'

const service = axios.create({
  validateStatus: function (status) {
    return status <= 500
  }
});

let URLS = [
  // 'http://139.217.130.3:802' 测试服
  {
    origin: 'gz.stu.52shuxue.com', // 生产
    url: 'http://api.pro.52shuxue.com:801'
  },
  {
    origin: 'demo.cz.stu.52shuxue.com', // 演示
    url: 'http://139.217.130.3:801'
  },
  {
    origin: 'demo.high.stu.52shuxue.com', // 高中
    url: 'http://demo.high.api.52shuxue.com'
  },
  {
    origin: 'xx.stu.52shuxue.com', // 小学
    url: 'http://139.219.135.125:803'
  }
]
let baseURL = URLS.find(v => location.origin.indexOf(v.origin) >= 0)?.url || 'http://139.217.130.3:802'

service.defaults.baseURL = baseURL
service.defaults.timeout = 12000
service.interceptors.request.use(config => {
  let token = getToken()
  if (token) {
    config.headers['token'] = token
  }
  return config;
}, error => {
  return Promise.reject(error)
});
/** 
 * 请求失败后的错误统一处理 
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, errMsg) => {
  // 状态码判断
  switch (status) {
    case 400:
      return '服务器不理解请求的语法'
    case 403:
      return '服务器拒绝请求'
    case 404:
      return '未找到请求的资源'
    case 405:
      return '禁用请求中指定的方法'
    case 500:
      return '服务器内部错误'
    // case 502:
    //   store.dispatch('logout').then(() => {
    //     location.reload();
    //   });
    //   break
    case 503:
      return '服务不可用'
    default:
      // if (errMsg) {
      //   console.error('status----', status)
      // }
      break
  }
}

const myInterceptor = service.interceptors.response.use(({ status, data }) => {
  let errorMsg = errorHandle(status, data.msg)
  if (errorMsg) {
    Message.error('网络错误')
  } else if (data.error !== ERROR_CODE) {
    if (data.error == 5001) {
      removeUserinfo()
      router.push('/login')
    }
    if (data && data?.msg) {
      Message.error(data?.msg)
    } else {
      Message.error('网络错误')
    }
  }
  
  let userinfo = getUserinfo()
  if (userinfo) {
    userinfo = JSON.parse(userinfo)
    store.commit('setUserinfo', userinfo)
  }

  return data
}, error => {
  Message.error('网络不佳，请重试！')
  return Promise.resolve(error)
})

export default service;
