<template>
  <div>
    <h2>重置密码</h2>
    <el-steps :active="active" finish-status="success" class="step">
      <el-step title="验证身份"></el-step>
      <el-step title="设置新密码"></el-step>
      <el-step title="重置成功"></el-step>
    </el-steps>
    <div v-if="active === 0" class="section">
      <el-form
        :model="checkForm"
        :rules="checkRule"
        ref="checkForm"
        class="forget-form"
      >
        <el-form-item prop="phone">
          <el-input
            placeholder="手机号"
            v-model="checkForm.phone"
            class="user"
            autocomplete="off"
            type="number"
            @input="() => (checkForm.phone = checkForm.phone.trim())"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="code" class="code-form">
          <el-input
            placeholder="短信验证码"
            v-model="checkForm.code"
            class="user"
            autocomplete="off"
            @input="() => (checkForm.code = checkForm.code.trim())"
          >
          </el-input>
          <div class="code">
            <span @click="sendCode">{{startInterval ? '重新发送' : '发送验证码'}}</span>
            <span v-if="startInterval">（{{interval}}s）</span>
          </div>
        </el-form-item>
      </el-form>
      <div class="footer fr">
        <span @click="checkSubmit" class="btn">下一步</span>
        <span @click="back">返回</span>
      </div>
    </div>
    <div v-show="active === 1" class="section">
      <el-form
        :model="settingForm"
        :rules="settingRule"
        ref="settingForm"
        class="forget-form"
      >
        <el-form-item prop="password">
          <el-input
            show-password
            placeholder="6-20位新密码"
            v-model="settingForm.password"
            class="user"
            autocomplete="off"
            type="number"
            @input="() => (settingForm.password = settingForm.password.trim())"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="password_confirmation">
          <el-input
            show-password
            placeholder="再次确认"
            v-model="settingForm.password_confirmation"
            class="user"
            autocomplete="off"
            type="number"
            @input="() => (settingForm.password_confirmation = settingForm.password_confirmation.trim())"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span @click="settingSubmit" class="btn">下一步</span>
    </div>
    <div v-show="active >= 2" class="section fc">
      <img src="../assets/images/lock.png" alt="">
      <span class="title">密码重置成功，请妥善保存您的账户信息</span>
      <span class="btn" @click="login">去登录</span>
    </div>
  </div>
</template>

<script>
// 引入首页背景图片
import { forget, changePassword, sendCode } from "api";

import { ERROR_CODE } from "utils/config";
import { deepClone, encry } from "utils/util";

export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('密码不能为空'));
      } else if (value.length < 6 || value.length > 20) {
        callback(new Error('密码格式有误！'))
      } else if (value !== this.settingForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      active: 0,
      isLoading: false,
      loginForm: {
        username: "",
        password: "",
      },
      checkForm: {
        phone: '',
        code: ''
      },
      checkRule: {
        phone: [
          {
            required: true,
            message: '手机号不能为空',
            trigger: 'blur'
          },
          {
            min: 6,
            max: 16,
            message: '长度在6-16个字符',
            trigger: 'blur'
          }
        ],
        code: [
          { 
            required: true, 
            message: '验证码不能为空', 
            trigger: 'blur' 
          },
          { 
            min: 4, 
            max: 6, 
            message: '长度在4-6个字符', 
            trigger: 'blur' 
          }
        ]
      },
      settingForm: {
        password: '',
        password_confirmation: ''
      },
      settingRule: {
        password: [
          { 
            required: true, 
            message: '密码不能为空', 
            trigger: 'blur' 
          },
          { 
            min: 6, 
            max: 20, 
            message: '密码格式有误！', 
            trigger: 'blur' 
          }
        ],
        password_confirmation: [
          { 
            validator: validatePass 
          }
        ],
      },
      startInterval: false,
      interval: 60
    }
  },
  methods: {
    back() {
      this.$router.replace({path: '/login', query: {action: 'login'}})
    },
    login() {
      this.active++
      setTimeout(() => {
        this.back()
      }, 500)
    },
    settingSubmit() {
      this.$refs.settingForm.validate(async (valid) => {
        if (valid) {
          let _settingForm = deepClone(this.settingForm)
          _settingForm.phone = this.checkForm.phone
          _settingForm.password = encry(_settingForm.password)
          _settingForm.password_confirmation = encry(_settingForm.password_confirmation)
          let res = await changePassword(_settingForm)
          if (res.error === ERROR_CODE) {
            this.$message.success(res.msg)
            this.active++
          }
        }
      })
    },
    checkSubmit() {
      this.$refs.checkForm.validate(async (valid) => {
        if (valid) {
          let res = await forget(this.checkForm)
          if (res.error === ERROR_CODE) {
            this.$message.success(res.msg)
            this.active++
          }
        }
      })
    },
    async sendCode() {
      let phone = this.checkForm.phone
      if (phone.length === 0) {
        this.$message.warning('请输入手机号码')
        return
      }
      if (phone.length < 6 || phone.length > 20) {
        this.$message.warning('手机号码格式有误！')
        return
      }
      if (this.startInterval) {
        return
      }
      let res = await sendCode({phone: encry(phone)})
      if (res.error === ERROR_CODE) {
        this.$message.success(res.msg)
      } else {
        this.$message.warning(res.msg)
      }
      this.startInterval = true
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        if (this.interval === 0) {
          clearInterval(this.timer)
          this.startInterval = false
          this.interval = 60
        }
        this.interval--
      }, 1000)
    },
    next() {
      if (this.active++ > 2) this.active = 0;
    }
  },
}
</script>

<style lang="scss" scoped>
h2 {
  font-size: 40px;
  font-weight: bold;
  color: black;
  margin-top: 55px;
}
.forget-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  .user {
    width: 460px;
    font-size: 18px;
  }
  .code-form {
    position: relative;
    .code {
      position: absolute;
      right: 15px;
      top: 0;
      span {
        color: #878A93;
        font-size: 18px;
        &:first-child {
          color: #1F56D5;
          cursor: pointer;
        }
      }
    }
  }
}
.step {
  margin-top: 53px;
  width: 512px;
  ::v-deep .is-success {
    color: #1F56D5;
    border-color: #1F56D5;
    .is-text {
      color: #1F56D5;
      border-color: #1F56D5;
    }
  }
  ::v-deep .el-step__main {
    text-align: center;
    transform: translateX(-50%);
    margin-left: 12px;
  }
}
.section {
  padding: 56px 0;
  text-align: center;
  align-items: center;
  .btn {
    display: inline-block;
    text-align: center;
    border-radius: 32px;
    font-size: 20px;
    width: 189px;
    height: 65px;
    line-height: 65px;
    color: white;
    background: #1F56D5;
    cursor: pointer;
  }
  .footer {
    justify-content: space-between;
    span {
      cursor: pointer;
      &:last-child {
        display: inline-block;
        text-align: center;
        border-radius: 32px;
        font-size: 20px;
        width: 188px;
        height: 64px;
        line-height: 64px;
        background: #E8EDF8;
        color: #343434;
      }
    }
  }
  img {
    width: 94px;
    margin-top: 38px;
  }
  .title {
    margin-top: 33px;
    color: #5D5D5D;
    font-size: 20px;
    margin-bottom: 56px;
  }
}
</style>