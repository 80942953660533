import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userinfo: {}
  },
  mutations: {
    setUserinfo(state, obj) {
      state.userinfo = obj
    }
  },
  actions: {
  },
  modules: {
  }
})
