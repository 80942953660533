<template>
  <div class="login-container">
    <Register v-if="action === 'reg'" />
    <ForgetPassword v-else-if="action === 'forget'" />
    <Login v-else />
  </div>
</template>

<script>
import Login from './login.vue'
import Register from './register.vue'
import ForgetPassword from './forgetPassword.vue'

export default {
  data() {
    return {
      action: 'login'
    }
  },
  components: {
    Login,
    Register,
    ForgetPassword
  },
  watch: {
    '$route': {
      handler: function({query}) {
        this.action = query.action
      },
      immediate: true
    }
  }
}

</script>

<style lang="scss" scoped>
.login-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
  box-shadow: 0px 0px 10px 0px rgba(233, 234, 239, 0.45);
  width: 566px;
  box-sizing: border-box;
  padding: 0 52px;
}
</style>