<template>
  <div>
    <h5>欢迎登录~</h5>
    <h2>学生登录</h2>
    <el-form
      :model="loginForm"
      :rules="rule"
      ref="loginForm"
      class="login-form"
    >
      <el-form-item prop="username">
        <el-input
          placeholder="手机号"
          v-model="loginForm.username"
          class="user"
          autocomplete="off"
          type="number"
          @input="() => (loginForm.username = loginForm.username.trim())"
        >
        </el-input>
      </el-form-item>
      <el-form-item prop="password" class="pass-form">
        <el-input
          type="password"
          placeholder="密码"
          v-model="loginForm.password"
          @keyup.enter.native="login"
          class="user"
          autocomplete="off"
        >
        </el-input>
      </el-form-item>
      <div class="find-pwd">
        <span @click="forgetPwd">找回密码</span>
      </div>
      <el-form-item>
        <el-button
          class="login-btn"
          type="primary"
          @click="login"
          :loading="isLoading"
          >登录</el-button
        >
      </el-form-item>
      <div class="register-account">
        <!-- <span @click="register">注册账号</span> -->
      </div>
    </el-form>
  </div>
</template>

<script>
// 引入首页背景图片
import { login } from "api";

import { ERROR_CODE } from "utils/config";
import { setUserinfo } from "utils/util";

export default {
  data() {
    return {
      isLoading: false,
      loginForm: {
        username: "",
        password: "",
      },
      rule: {
        username: [
          {
            required: true,
            message: "请填写手机号",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请填写登录密码",
            trigger: "blur",
          },
          {
            type: "string",
            min: 4,
            message: "密码长度不能低于4个字符",
            trigger: "blur",
          },
        ],
      },
    }
  },
  methods: {
    forgetPwd() {
      this.$router.replace({path: '/forget', query: {action: 'forget'}})
    },
    register() {
      this.$router.replace({path: '/register', query: {action: 'reg'}})
    },
    /**登录 */
    login() {
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          this.isLoading = true;
          let res = await login(this.loginForm);
          this.isLoading = false;
          if (res.error == ERROR_CODE) {
            let obj = res.data || {};
            setUserinfo(JSON.stringify(obj));
            this.$router.push("/");
          }
        }
      });
    },
  },
}
</script>

<style lang="scss" scoped>
h5 {
  color: black;
  font-size: 20px;
  font-weight: normal;
  margin-top: 40px;
  opacity: .6;
}
h2 {
  margin-top: 18px;
  font-size: 40px;
  font-weight: bold;
  color: black;
}
.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 0 5px 0;
  margin-top: 57px;
  .user {
    width: 460px;
    font-size: 18px;
  }
  .login-btn {
    border-radius: 32px;
    width: 190px;
    height: 65px;
    background: #1f56d5;
    border: 1px solid #1f56d5;
    font-size: 20px;
    margin-top: 75px;
  }
  .pass-form {
    margin-bottom: 0;
  }
  .find-pwd {
    margin-top: 16px;
    width: 100%;
    text-align: right;
    padding-right: 19px;
    span {
      color: #1F56D5;
      font-size: 16px;
      cursor: pointer;
    }
  }
  .register-account {
    text-align: center;
    width: 100%;
    margin-bottom: 80px;
    span {
      color: #1F56D5;
      font-size: 16px;
      cursor: pointer;
    }
  }
}
</style>